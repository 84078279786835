<template>
  <transition name="fade" appear>
    <div class="home">
      <el-image style="width: 302px; height: 302px" :src="logo" class="img"></el-image>
      <div class="title">{{ title }}</div>
      <audio autoplay controls :src="video" controlsList="nodownload" oncontextmenu="return false"></audio>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "fmDetail",
  data() {
    return {
      logo: "",
      title: "",
      video: "",  
      audioCode:"",

    };
  },
  methods: {  
    getAudioDetail(){
      var getData={};
      getData.audioCode=this.audioCode;
      this.$api.home.getAudioDetail(getData).then((res) => {
        if (res.data.code === 200) {
          this.logo = res.data.data.logo;
          this.title = res.data.data.audioName;
          this.video = res.data.data.url;

        }
      });
    },
   //禁用F12
    forbidden(){
       document.onkeydown = function() {
        var e = window.event || arguments[0];
        if (e.keyCode == 123) {
            return false;
        }
    }
    },
  //禁用右键
    forRightClick(){
      document.oncontextmenu = function() {
        return false;
    }
    }
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.audioCode=this.$route.query.audioCode;
    this.getAudioDetail();
        this. forbidden()
    this.forRightClick()
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 88px;
  padding: 0 50px;
  background-color: white;
  .img {
    border-radius: 6px;
  }
  .title {
    margin-top: 40px;
    font-size: 20px;
  }
  audio {
    width: 600px;
    height: 43px;
    margin-top: 50px;
  }
}
</style>
